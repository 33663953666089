import { connect } from 'react-redux'
import { setContractor } from '../../store/contractor/actions'
import { setHeaderBack, setHeaderSignOut } from '../../store/header/actions'
import TermsAndConditions from './TermsAndConditions'

const mapStateToProps = state => ({
  contractor: state.contractor.registeredContractor
})

const mapDispatchToProps = dispatch => ({
  storeContractor: (contractor) => {
    dispatch(setContractor(contractor))
  },
  setHeader: (state) => {
    dispatch(setHeaderBack(state))
  },
  setSignOutHeader: (state) => {
    dispatch(setHeaderSignOut(state))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions)
