import React, { Component } from "react";
import {
  Container,
  FilledButton,
  TextButton,
  SelectField,
  FormGroup
} from "@jsluna/react";

import api from "../../utils/api";
import { signInOutType, siteTypes } from "../../utils/constants";
import errorIcon from '../../assets/error.png'

import "../../sass/components/tasktimeselection.scss";

let arrayLength = 0
let hoursArray = 0
let minutesArray = 0
class TaskTimeSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hours: [],
      minutes: [],
      isAutocompleteFieldSelected: true,
      error: "",
      taskTimeArray: [],
      selectedHours: this.props.taskTime.hours,
      selectedMinutes: this.props.taskTime.minutes,
      initialMinutes: [],
      initialHour: [],
      latitude: null,
      longitude: null,
      errorMessageTask1: true,
      errorMessageTask2: true,
      errorMessageTask3: true,
      errorMessageTask4: true,
      errorMessageTask5: true,
    };
  }

  componentDidMount() {

    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(this.getLocation)
    }
    this.setState({
      hours: [
        { label: "0 hours", value: "00 hours" },
        { label: "1 hour", value: "01 hours" },
        { label: "2 hours", value: "02 hours" },
        { label: "3 hours", value: "03 hours" },
        { label: "4 hours", value: "04 hours" },
        { label: "5 hours", value: "05 hours" },
        { label: "6 hours", value: "06 hours" },
        { label: "7 hours", value: "07 hours" },
        { label: "8 hours", value: "08 hours" },
        { label: "9 hours", value: "09 hours" },
        { label: "10 hours", value: "10 hours" },
        { label: "11 hours", value: "11 hours" },
        { label: "12 hours", value: "12 hours" },
      ],
      minutes: [
        { label: "0 minutes", value: "00 minutes" },
        { label: "15 minutes", value: "15 minutes" },
        { label: "30 minutes", value: "30 minutes" },
        { label: "45 minutes", value: "45 minutes" },
      ],
    });

    let tempSelectedMinutes = []
    let tempSelectedHours = []
    this.props.task.taskSelectedItems.map((item) => {
      if (item.defaultChecked === true) {
        this.state.initialMinutes.push({ label: item.label, minute: "30 minutes" })
        this.state.initialHour.push({ label: item.label, hour: "00 hours" })

        if (this.props.taskTime.hours.length == 0) {
          tempSelectedMinutes.push({ label: item.label, item: "30 minutes" })
          tempSelectedHours.push({ label: item.label, item: "00 hours" })
        }


      }
    });

    if (this.props.taskTime.hours.length >= 1) {
      this.props.task.taskSelectedItems.map((itemChild) => {
        if (itemChild.defaultChecked === true) {

          let isValueExists = this.hasValueDeep(this.props.taskTime.hours, itemChild.label)

          if (!isValueExists) {
            this.state.selectedHours.push({ label: itemChild.label, item: "00 hours" })
            this.state.selectedMinutes.push({ label: itemChild.label, item: "30 minutes" })

          }


        }
      });
    }
    if (this.props.taskTime.hours.length == 0) {
      this.setState({
        selectedMinutes: tempSelectedMinutes,
        selectedHours: tempSelectedHours
      });
    }
    else {
      this.props.task.taskSelectedItems.map((item) => {
        if (item.defaultChecked === true) {
        }
      });
    }
  }

  componentWillUnmount() {
    arrayLength = 0
    hoursArray = 0
    minutesArray = 0
  }


  hasValueDeep = (json, findValue) => {
    const values = Object.values(json);
    let hasValue = values.includes(findValue);
    values.forEach((value) => {
      if (typeof value === "object") {
        hasValue = hasValue || this.hasValueDeep(value, findValue);
      }
    })
    return hasValue;
  }


  handleChangeHours = (e, label) => {
    if (e) {
      let tempHours1 = []
      let tempHours2 = []

      let tempMinArray = this.state.selectedHours;
      this.state.selectedHours.map((itemHrs) => {
        tempHours1.push(itemHrs.label)
      })
      this.props.task.taskSelectedItems.map((itemHs) => {
        if (itemHs.defaultChecked === true) {
          tempHours2.push(itemHs.label)
        }

      })

      var arrayHrs = [], diff = [];

      for (var i = 0; i < tempHours1.length; i++) {
        arrayHrs[tempHours1[i]] = true;
      }

      for (var i = 0; i < tempHours2.length; i++) {
        if (arrayHrs[tempHours2[i]]) {
          delete arrayHrs[tempHours2[i]];
        } else {
          arrayHrs[tempHours2[i]] = true;
        }
      }

      for (var valHrs in arrayHrs) {
        diff.push(valHrs);
      }
      diff.map((itemHours) => {
        tempMinArray.push({
          label: itemHours,
          item: "00 hours"
        })
      })

      tempMinArray.map((item) => {
        if (label == item.label) {
          item.item = e.target.value
        }
      })
      if (label === "Check and fix" && e.target.value !== "00") {
        this.setState({ errorMessageTask1: true })
      }
      if (label === "Audit" && e.target.value !== "00") {
        this.setState({ errorMessageTask2: true })
      }
      if (label === "Range review support" && e.target.value !== "00") {
        this.setState({ errorMessageTask3: true })
      }
      if (label === "Shipper/POS implementation" && e.target.value !== "00") {
        this.setState({ errorMessageTask4: true })
      }
      if (label === "Other" && e.target.value !== "00") {
        this.setState({ errorMessageTask5: true })
      }
      this.setState({ selectedHours: tempMinArray })
      this.state.taskTimeArray.push(this.state.selectedHours);
    }
    else {
      this.state.selectedHours.map((item) => {
        if (item.label === label) {
          item.label = null
          item.item = null

        }
      })
    }
  };
  getLocation = (location) => {
    this.locationSuccess(location.coords.latitude, location.coords.longitude)
  }
  locationSuccess = (latitude, longitude) => {
    this.setState({ latitude, longitude })
  }
  handleChangeMinutes = (e, label) => {
    if (e) {
      let tempMinutes1 = []
      let tempMinutes2 = []

      let tempMinArray = this.state.selectedMinutes;
      this.state.selectedMinutes.map((itemMin) => {
        tempMinutes1.push(itemMin.label)
      })
      this.props.task.taskSelectedItems.map((itemMins) => {
        if (itemMins.defaultChecked === true) {
          tempMinutes2.push(itemMins.label)
        }

      })

      var arrayMin = [], diff = [];

      for (var i = 0; i < tempMinutes1.length; i++) {
        arrayMin[tempMinutes1[i]] = true;
      }

      for (var i = 0; i < tempMinutes2.length; i++) {
        if (arrayMin[tempMinutes2[i]]) {
          delete arrayMin[tempMinutes2[i]];
        } else {
          arrayMin[tempMinutes2[i]] = true;
        }
      }

      for (var valMin in arrayMin) {
        diff.push(valMin);
      }
      diff.map((itemMinutes) => {
        tempMinArray.push({
          label: itemMinutes,
          item: "30 minutes"
        })
      })

      tempMinArray.map((item) => {
        if (label === item.label) {
          item.item = e.target.value
        }
      })
      if (label === "Check and fix" && e.target.value !== "00") {
        this.setState({ errorMessageTask1: true })
      }
      if (label === "Audit" && e.target.value !== "00") {
        this.setState({ errorMessageTask2: true })
      }
      if (label === "Range review support" && e.target.value !== "00") {
        this.setState({ errorMessageTask3: true })
      }
      if (label === "Shipper/POS implementation" && e.target.value !== "00") {
        this.setState({ errorMessageTask4: true })
      }
      if (label === "Other" && e.target.value !== "00") {
        this.setState({ errorMessageTask5: true })
      }
      this.setState({ selectedMinutes: tempMinArray })
      this.state.taskTimeArray.push(this.state.selectedMinutes);
    }
    else {
      this.state.selectedMinutes.map((item) => {
        if (item.label === label) {
          item.label = null
          item.item = null
        }
      })
    }
  };

  hasValueHours = (json, findValue) => {
    if (json) {
      const val = json.find(o => o.label === findValue)
      return val && val.item
    }
  };

  handleComplete = () => {
    let arrayLength = 0;
    let hoursArray = 0;
    let minutesArray = 0;
    let task1 = []
    let task2 = []
    let task3 = []
    let task4 = []
    let task5 = []
    let ValidatedSelectedHours = []
    let ValidatedSelectedMinutes = []
    this.props.task.taskSelectedItems.map((item) => {
      if (item.defaultChecked === true) {
        this.state.selectedHours.map((itemChildVariable) => {
          if (item.label === itemChildVariable.label) {
            ValidatedSelectedHours.push({ label: itemChildVariable.label, item: itemChildVariable.item })
          }
        })
        this.state.selectedMinutes.map((itemChildVariable) => {
          if (item.label === itemChildVariable.label) {
            ValidatedSelectedMinutes.push({ label: itemChildVariable.label, item: itemChildVariable.item })
          }
        })
        ValidatedSelectedHours.map((itemChild) => {

          if (itemChild.label === "Check and fix") {
            task1.push(itemChild.item)
          }
          if (itemChild.label === "Audit") {
            task2.push(itemChild.item)
          }
          if (itemChild.label === "Range review support") {
            task3.push(itemChild.item)
          }
          if (itemChild.label === "Shipper/POS implementation") {
            task4.push(itemChild.item)
          }
          if (itemChild.label === "Other") {
            task5.push(itemChild.item)
          }
        });
        ValidatedSelectedMinutes.map((itemChild) => {
          if (itemChild.label === "Check and fix") {
            task1.push(itemChild.item)
          }
          if (itemChild.label === "Audit") {
            task2.push(itemChild.item)
          }
          if (itemChild.label === "Range review support") {
            task3.push(itemChild.item)
          }
          if (itemChild.label === "Shipper/POS implementation") {
            task4.push(itemChild.item)
          }
          if (itemChild.label === "Other") {
            task5.push(itemChild.item)
          }
        });
        arrayLength++
      }
    })
    if ((task1.includes('00 hours')) && (task1.includes('00 minutes'))) {
      this.setState({ errorMessageTask1: false })
    }
    if ((task2.includes('00 hours')) && (task2.includes('00 minutes'))) {
      this.setState({ errorMessageTask2: false })
    }
    if ((task3.includes('00 hours')) && (task3.includes('00 minutes'))) {
      this.setState({ errorMessageTask3: false })
    }
    if ((task4.includes('00 hours')) && (task4.includes('00 minutes'))) {
      this.setState({ errorMessageTask4: false })
    }
    if ((task5.includes('00 hours')) && (task5.includes('00 minutes'))) {
      this.setState({ errorMessageTask5: false })
    }
    
    const hoursTemp = [...new Set(ValidatedSelectedHours.map(obj => JSON.stringify(obj)))]
      .map(str => JSON.parse(str));

    const minutesTemp = [...new Set(ValidatedSelectedMinutes.map(obj => JSON.stringify(obj)))]
      .map(str => JSON.parse(str));

    minutesTemp.map((item) => {
      hoursTemp.map((itemParent) => {
        if (item.item && itemParent.item) {
          if (item.label === itemParent.label) {
            if (!((parseInt(item.item) === 0) && (parseInt(itemParent.item) === 0))) {
              minutesArray++
            }
          }
        }
      })
    })

    hoursTemp.map((item) => {
      minutesTemp.map((itemParent) => {
        if (item.item && itemParent.item) {
          if (item.label === itemParent.label) {
            if (!((parseInt(item.item) === 0) && (parseInt(itemParent.item) === 0))) {
              hoursArray++
            }
          }
        }
      })
    })    

    if (hoursArray === arrayLength && minutesArray === arrayLength) {

      const {
        history,
        visitId,
        employerId,
        contractorId,
        signedInStore,
        task,
        supplier,
        deviceType,
      } = this.props;

      const { latitude, longitude } = this.state;

      let taskID = [];
      this.props.task.taskSelectedItems.map((item) => {
        if (item.defaultChecked === true) {
          taskID.push(item.value);
        }
      });
      let Totalminutes = []
      let TotalHours = []
      ValidatedSelectedHours.map((item) => {
        if (item.item) {
          TotalHours.push(parseInt(item.item))
        }

      })
      ValidatedSelectedMinutes.map((item) => {
        if (item.item) {
          Totalminutes.push(parseInt(item.item))
        }

      })
      let SignedInSiteType = null
      if (this.props.signedInSiteType === "Store") {
        SignedInSiteType = siteTypes.store
      }
      else if (this.props.signedInSiteType === "Convenience") {
        SignedInSiteType = siteTypes.convenience
      }
      else {
        SignedInSiteType = siteTypes.depot
      }

      const visitTaskDetails = {
        TaskId: taskID,
        HoursEntered: TotalHours,
        MinutesEntered: Totalminutes,
        SupplierId: supplier.supplierId,
        EmployerId: employerId,
        SignedInStoreId: signedInStore.code,
        SignedInStoreName: signedInStore.name,
        SignInType: signInOutType.password,
        SignInDevice: deviceType,
        SignedInSiteTypeId: SignedInSiteType,
        Latitude: latitude,
        Longitude: longitude
      };

      api.saveVisitTask(visitTaskDetails).then((res) => {
        if (res) {
          if (hoursArray === arrayLength && minutesArray === arrayLength) {
            history.push("/MerchandiserSignIn");
          }
        }
      });

    }
  }

  handleBack = () => {
    const hours = this.props.taskTime;
    hours.hours = this.state.selectedHours;
    this.props.setTaskTime(hours);
    const minutes = this.props.taskTime;
    minutes.minutes = this.state.selectedMinutes;
    this.props.setTaskTime(minutes);
    this.props.history.push('/task')
  }


  render() {
    const hours = this.state.hours;
    const minutes = this.state.minutes;

    return (
      <Container className="ln-o-container ln-o-container--soft ln-u-soft-bottom ln-u-margin-top*2">
        <Container size="xs">
          <div className="ln-c-form-group ln-o-grid__item ln-u-1/1@xs ln-u-soft-right">
            <h2>How long will this task take?</h2>
          </div>
          {this.props.task.taskSelectedItems.map((item) => {
            if (item.defaultChecked === true) {
              return (
                <div>
                  <div>
                    <h5 className="taskname">
                      {item.label}
                    </h5>
                  </div>
                  <div className="ln-o-grid__item ln-u-soft-right ln-u-1/1 ln-u-1/3@xs autocomplete">
                    {this.state.initialHour.map((items) => {
                      if (items.label === item.label) {
                        return (
                          <SelectField
                            name="autocomplete-field-1"
                            options={hours}
                            defaultValue={this.hasValueHours(
                              this.state.selectedHours,
                              item.label
                            ) ? this.hasValueHours(
                              this.state.selectedHours,
                              item.label
                            ) : items.hour}
                            onChange={(e) => this.handleChangeHours(e, item.label)}
                            defaultSelectedOption={{ label: this.state.hours }}
                            error={item.label === "Check and fix" ? !this.state.errorMessageTask1 : item.label === "Audit" ? !this.state.errorMessageTask2 : item.label === "Range review support" ? !this.state.errorMessageTask3 : item.label === "Shipper/POS implementation" ? !this.state.errorMessageTask4 : item.label === "Other" ? !this.state.errorMessageTask5 : null}
                          />
                        )

                      }
                    })}

                  </div>
                  <div className="ln-o-grid__item ln-u-soft-right ln-u-1/1 ln-u-1/3@xs autocomplete">
                    {this.state.initialMinutes.map((items) => {
                      if (items.label === item.label) {
                        return (
                          <SelectField
                            name="autocomplete-field-2"
                            options={minutes}
                            defaultValue={
                              this.hasValueHours(
                                this.state.selectedMinutes,
                                item.label
                              ) ? this.hasValueHours(
                                this.state.selectedMinutes,
                                item.label
                              ) : items.minute

                            }
                            onChange={(e) => this.handleChangeMinutes(e, item.label)}
                            error={item.label === "Check and fix" ? !this.state.errorMessageTask1 : item.label === "Audit" ? !this.state.errorMessageTask2 : item.label === "Range review support" ? !this.state.errorMessageTask3 : item.label === "Shipper/POS implementation" ? !this.state.errorMessageTask4 : item.label === "Other" ? !this.state.errorMessageTask5 : null}
                          />
                        )

                      }
                    })}

                  </div>
                  {(item.label === "Check and fix" && this.state.errorMessageTask1 === false || item.label === "Audit" && this.state.errorMessageTask2 === false || item.label === "Range review support" && this.state.errorMessageTask3 === false || item.label === "Shipper/POS implementation" && this.state.errorMessageTask4 === false || item.label === "Other" && this.state.errorMessageTask5 === false) && (
                    <div className="form-error-div" style={{ marginLeft: '1.5rem', marginTop: '0rem' }}>
                      <div style={{ marginRight: '10px' }}>
                        <img src={errorIcon} />
                      </div>
                      <FormGroup error="Please add a time for this task"></FormGroup>
                    </div>
                  )}
                </div>
              );
            }

          })}

          <div className="ln-o-grid ln-o-grid--bottom ln-o-grid--matrix ln-u-soft-right ln-u-soft-left ln-u-soft-top">
            <div className="ln-o-grid__item ln-u-1/1 ln-u-1/3@xs">
              <FilledButton
                className="ln-c-button ln-c-button--full ln-c-button--primary"
                type="button"
                onClick={this.handleComplete}
              >
                Complete
              </FilledButton>
              <TextButton
                fullWidth
                onClick={this.handleBack}
              >
                Back
              </TextButton>
            </div>
          </div>
        </Container>
      </Container>
    );
  }
}

export default TaskTimeSelection;
