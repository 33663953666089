import React from 'react'
import PropTypes from 'prop-types'
import {
  Container, FieldInfo, FormGroup, TextButton, TextInputField, FilledButton, OutlinedButton, Card,
} from '@jsluna/react'

import moment from 'moment'
import SignInButton from '../Buttons/SignInButton'
import api from '../../utils/api'
import redirectUser from '../../utils/routeHelper'
import LoadingOverlay from '../LoadingOverlay'   
import AlreadySignedInModal from '../AlreadySignedInModal'
import TCsChangedModal from '../TermsAndConditions/TCsChangedModal'
import NewTCsModal from '../TermsAndConditions/NewTCsModal'
import "../../sass/components/signin.scss"

class SignIn extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      gpsLoading: true,
      loading: false,
      gpsError: false,
      selectedStore: null,
      storeLocationError: false,
      noNearbyStoresMessage: 'You are not close to any sites',
      showTCsChangedModal: false,
      showTCModal: false,
      TCAccepted: false,
      latitude: null,
      longitude: null,
      acceptTCError: '',
      selectedSiteType: null,
      carRegistrationNumber: '',
      username: null,
      contractorId: null,
      showSuccessBanner: false,
      showFailureBanner: false,
      vehicleRegistration: null,
      vehicleRegFormOpen: false
    }
  }

  componentDidMount() {
    const {
      setHeader,
      history,
      setNearbyStores,
      setSignOutHeader
    } = this.props

    setHeader()
    setSignOutHeader(false)
    setNearbyStores(null)

    redirectUser(history)

    this.checkLocation()
    api.getUserState()
      .then((result) => {
        if (result !== null) {
          this.setState({ username: result.contractorState.contractor.username, contractorId: result.contractorState.contractor.id})
        }
      })
    api.getContractorDetails()
      .then((result) => {
        if (result!== null && result.carRegistration !== null) {
          this.setState({ vehicleRegistration: result.carRegistration })
        }  
        if(result.carRegistration === null){
          this.toggleRegForm()
        }
      })
  }

  componentWillReceiveProps(props) {
    if (props.store) {
      this.setState({ selectedStore: props.store.name, selectedSiteType: props.store.siteType })
    }
  }

  checkLocation = () => {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(this.locationSuccess, this.locationError)
    }
  }

  locationError = (error) => {
    const { message } = error
    let msg = message

    if(error.code === 1){
      msg = 'You need to turn on location services to find your nearest store and sign in'
    }

    // GPS unavailable
    if (error.code === 2) {
      msg = 'GPS is currently unavailable'
    }
    this.setState({
      loading: false, gpsLoading: false, gpsError: true, gpsErrorText: msg, selectedStore: null
    })
  }

  locationSuccess = (location) => {
    this.setState({ gpsError: false, gpsErrorText: '' })
    this.getClosestStores(location.coords.latitude, location.coords.longitude)
  }

  onCarRegistrationNumberChange = (e) => {
    var value = e.target.value.toUpperCase()  
    const regex = /^[a-zA-Z0-9]*$/;
    if(value.match(regex)){
      this.setState({ carRegistrationNumber: value })
    }
  }

  handleregistration = () => {
    const { setCarRegistrationNumber } = this.props;
    const { carRegistrationNumber } = this.state;
    if (carRegistrationNumber === '') {
      return
    }
    setCarRegistrationNumber(carRegistrationNumber)
    const carRegistartionDetails = {
      carRegistration: carRegistrationNumber,
    }
    api.updateCarRegistration(carRegistartionDetails).then ((res) => {
      if (res) {
        if (res.status === 200) {
          this.setState({ showSuccessBanner: true, vehicleRegistration: carRegistartionDetails.carRegistration, carRegistrationNumber: '' })
        }
        else {
          this.setState({ showFailureBanner: true, carRegistrationNumber: '' })
        }
        this.toggleRegForm()
        this.forceUpdate()
      }
    })
  }
  

  getClosestStores = (latitude, longitude) => {
    const { setNearbyStores, setChosenStore } = this.props

    this.setState({ latitude, longitude })
    api.getClosestProperties(latitude, longitude)
      .then((res) => {
        setNearbyStores(res)
        if (res === null || res === undefined || res.length === 0) {
          setChosenStore(null)
          this.setState({
            loading: false, gpsLoading: false, storeLocationError: true, selectedStore: null
          })
          return
        }
        if (res === 408) {
          this.setState({ loading: false, gpsLoading: false })
          return
        }
        this.setClosestStore(res)
        this.setState({ loading: false, gpsError: false, gpsLoading: false })
      })
      .catch(() => this.setState({
        loading: false, gpsLoading: false, gpsError: true, gpsErrorText: 'An error occurred getting your closest store'
      }))
  }

  setClosestStore = (res) => {
    const {
      store,
      setChosenStore,
      stores
    } = this.props
    const { selectedStore } = this.state

    if (res && (!selectedStore || stores.findIndex(s => s.code === store.code) === -1)) {
      setChosenStore(res[0])
    }
  }

  checkLocationChange = () => {
    this.setState({ loading: true })
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(this.onSignInCheckLocationChange, this.locationError)
    }
  }

  onSignInCheckLocationChange = (pos) => {
    const { latitude, longitude } = pos.coords
    this.checkStoreLocationChange(latitude, longitude)
  }

  checkStoreLocationChange = (latitude, longitude) => {
    const { selectedStore } = this.state
    const { setNearbyStores, setSignInStatus } = this.props

    api.getClosestProperties(latitude, longitude)
      .then((res) => {
        if (res && res.findIndex(s => s.name === selectedStore) === -1) {
          setNearbyStores(res)
          this.setClosestStore(res)

          const status = { hasWarning: true, statusText: 'Your location has changed. Please try again.' }

          setSignInStatus(status)
          this.setState({ loading: false, gpsLoading: false, storeLocationError: true })
          return true
        }
        if (res) {
          this.handleSignIn(latitude, longitude)
        } else {
          this.setState({
            loading: false, gpsLoading: false, storeLocationError: true, selectedStore: null
          })
        }
      })
      .catch(() => this.setState({ gpsLoading: false, gpsError: true, gpsErrorText: 'An error occurred getting your closest store' }))
  }

  checkTCs = async () => {
    const { user } = this.props
    const { latitude, longitude } = this.state

    const mostRecentTCsDate = await api.getLatestTCs()
    const usersLastAcceptedTCs = await api.getUsersLastAcceptedTCs(user.id)
    if (moment(usersLastAcceptedTCs).isBefore(mostRecentTCsDate)) {
      this.setState({ showTCsChangedModal: true, loading: false })
    } else {
      this.setState({ TCAccepted: true })
      this.handleSignIn(latitude, longitude)
    }
  }

  handleSignIn = async (latitude, longitude) => {
    const { selectedStore, TCAccepted } = this.state
    const {
      history, resetSignIn, requestSignIn, store
    } = this.props

    if (selectedStore) {
      resetSignIn()

      if (TCAccepted === false) {
        this.checkTCs()
      } else {
        this.checkLocationStatus()
        if (store.siteType === 'Store' || store.siteType === 'Convenience') {
          history.push('/firepolicy')
        } else {
          this.setState({ loading: false, gpsLoading: false })
          requestSignIn(latitude, longitude)
        }
      }
    }
  }

  checkLocationStatus = () => {
    const {
      gpsError,
      gpsErrorText,
      storeLocationError,
      gpsLoading,
      noNearbyStoresMessage,
      selectedStore
    } = this.state

    const { signInStatus } = this.props

    if (gpsError) {
      return <FieldInfo error>{gpsErrorText}</FieldInfo>
    }
    if (!gpsLoading && storeLocationError && selectedStore === null) {
      return <FormGroup warning={noNearbyStoresMessage} />
    }
    if (signInStatus !== null && signInStatus.status.hasWarning) {
      return <FormGroup warning={signInStatus.status.statusText} />
    }

    if (signInStatus !== null && signInStatus.status.hasError) {
      return <FieldInfo error>{signInStatus.status.statusText}</FieldInfo>
    }

    return ''
  }

  toggleRegForm = () => {
    this.setState((prevState) => ({
      ...prevState,
      vehicleRegFormOpen: !prevState.vehicleRegFormOpen
    }))
  }

  getFooter = () => {
    return <Container size="xs">
      <div className="ln-o-grid ln-o-grid--bottom ln-o-grid--matrix ln-u-soft-right ln-u-soft-left ln-u-soft-top">
        {!this.state.vehicleRegFormOpen && this.state.vehicleRegistration &&
        <div className="ln-o-grid__item ln-u-1/1 car-reg-width">
          <div className="ln-u-text-align-center ln-u-push-top ln-u-margin-top*1/2 store-name">
            <Container className="u-font-weight__bold">
              <p className="u-margin-bottom__small">{`Vehicle registration ${this.state.vehicleRegistration}`}</p>
            </Container>
            <div className='custom-text-button' onClick={() => this.toggleRegForm()}>
              Change Vehicle Registration
            </div>
          </div>
        </div>
        }
        { this.state.vehicleRegFormOpen &&
        <div className="ln-o-grid__item ln-u-1/1 car-reg-width">
          <TextInputField
            name="text-input-field-2"
            label="Add vehicle registration (optional)"
            keyboard="default"
            onChange={ e => this.onCarRegistrationNumberChange(e)}
            value={this.state.carRegistrationNumber}
            maxLength="10"
          />
          <div className="ln-u-push-bottom button"> 
           {!this.state.vehicleRegistration &&
           <OutlinedButton disabled={this.state.carRegistrationNumber === ''} onClick={this.handleregistration} fullWidth>Add registration</OutlinedButton>} 
           {this.state.vehicleRegistration &&
           <OutlinedButton disabled={this.state.carRegistrationNumber === ''} onClick={this.handleregistration} fullWidth>Update registration</OutlinedButton>}             
          </div>
        </div>
        }
      </div>
    </Container>
  }

  displayStoreName = () => {
    const { selectedStore, selectedSiteType, gpsError } = this.state
    if (!gpsError) {
      return (
        <Container className="u-font-weight__bold">
          {selectedStore}
          <p className="u-margin-bottom__small">{`${selectedSiteType ? `(${selectedSiteType})` : ''}`}</p>
        </Container>
      )
    }
    return ''
  }

  notThisStore = () => {
    const { history } = this.props
    this.checkLocation()
    history.push('/MultipleStores')
  }

  enableSignInBtn = () => {
    const {
      gpsError,
      selectedStore
    } = this.state
    const { store } = this.props

    if (store !== null && store.code !== null && !gpsError && selectedStore !== null) {
      return true
    }

    return false
  }

  acceptNewTCs = async () => {
    const { latitude, longitude } = this.state
    const { user, store } = this.props

    this.setState({ loading: true })

    const result = await api.userAcceptedTCs(user.id, store.code)
    if (result === 0) { // successful
      this.setState({ showTCModal: false, TCAccepted: true, loading: false }, () => this.handleSignIn(latitude, longitude))
    } else {
      this.setState({ loading: false, acceptTCError: 'Something went wrong, please try again.' })
    }
  }

  render() {
    const {
      gpsLoading,
      loading,
      showTCsChangedModal,
      showTCModal,
      acceptTCError
    } = this.state

    const { signInStatus, resetSignIn, history, user } = this.props

    let isLoading = false

    if (gpsLoading || loading) {
      isLoading = true
    }

    return (
      <div>
        <AlreadySignedInModal
          isOpen={signInStatus.status.alreadySignedIn}
          title="Already Signed In"
          details="You are already signed in"
          okClick={() => {
            this.setState({ loading: true })
            resetSignIn()
            redirectUser(history)
            
          }}
        />
        <TCsChangedModal isOpen={showTCsChangedModal} continueClick={() => this.setState({ showTCsChangedModal: false, showTCModal: true })} />
        <NewTCsModal isOpen={showTCModal} acceptClick={() => this.acceptNewTCs()} error={acceptTCError} close={() => { this.setState({ showTCModal: false }) }} />

        {!isLoading && !signInStatus.loading
          && (
            <Container className="ln-u-text-align-center">
             {this.state.showFailureBanner &&
              <div className="signinerrorbanner">
              <div className="errorbanner">Sorry, we can't save your vehicle<br></br> registration right now. Please try again.</div>
              <button className="crossiconfailure"  onClick={() => this.setState({showFailureBanner: false})}>X</button>
              </div>}
              {this.state.showSuccessBanner &&
              <div className="banner">
              <div className="successbanner">Vehicle registration added</div>
              <button className="crossiconsuccess"  onClick={() => this.setState({showSuccessBanner: false})}>X</button>
              </div>}
              <div className="ln-o-grid ln-o-grid--matrix c-button-menu">
                <div className="ln-o-grid__item ln-u-3/4@xs ln-u-1/1 ln-u-text-align-center c-button-menu2__item u-z-index__1" style={{align: 'center'}}>
                  <SignInButton className="signinbutton" enabled={this.enableSignInBtn()} onClick={this.checkLocationChange} />
                  <div className="maintitle1">Welcome to Sainsbury's</div>
                  <div className="maintitle2">{user.fullName}</div>
                  <div className="ln-u-text-align-center ln-u-push-top ln-u-margin-top*1/2 store-name">
                  {this.displayStoreName()}
                  </div>
                  <div className="ln-u-text-align-center ln-u-push-top ln-u-margin-top*1/2">
                    <div className='custom-text-button' onClick={() => this.notThisStore()}>Switch to a different site</div>
                    <div>
                    {this.checkLocationStatus()}
                    </div>
                    <FilledButton className="signinbutton custom-margin-top" onClick={this.checkLocationChange}>Sign in to site</FilledButton>
                    <div>{this.getFooter()}</div>
                  </div>
                </div>
              </div>
            </Container>
          )
        }
        {isLoading
          && <LoadingOverlay text={gpsLoading && 'Locating you...'} />
        }
        {signInStatus.loading && !isLoading
          && <LoadingOverlay text="Signing you in..." />
        }
      </div>
    )
  }
}

SignIn.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  setHeader: PropTypes.func.isRequired,
  setSignOutHeader: PropTypes.func.isRequired,
  setSignInStatus: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  setNearbyStores: PropTypes.func.isRequired,
  setChosenStore: PropTypes.func.isRequired,
  setCarRegistrationNumber: PropTypes.func.isRequired,
  store: PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    distance: PropTypes.number.isRequired,
    siteType: PropTypes.string.isRequired
  }),
  stores: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    distance: PropTypes.number.isRequired,
    siteType: PropTypes.string.isRequired
  })),
  resetSignIn: PropTypes.func.isRequired,
  requestSignIn: PropTypes.func.isRequired,
  signInStatus: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    status: PropTypes.shape({
      hasWarning: PropTypes.bool.isRequired,
      hasError: PropTypes.bool.isRequired,
      statusText: PropTypes.string
    })
  }).isRequired
}

SignIn.defaultProps = {
  store: null,
  stores: null,
}

export default SignIn
