import React from 'react'
import { PropTypes } from 'prop-types'
import {
  Card,
  Display3,
  FlagWrapper,
  FlagComponent,
  FlagBody,
  OutlinedButton,
  FilledButton,
  Text,
  Link
} from '@jsluna/react'
import { ArrowRight } from '@jsluna/icons'
import { Alert } from '@jsluna/alert'
import '@jsluna/alert/css/main.css'
import WithFooter from '../../Footer'
import FooterWithButton from '../../Footer/FooterWithButton'
import api from '../../../utils/api'
import Title from '../../PageTitle'
import DeletePassModal from '../DeletePassModal'
import LoadingOverlay from '../../LoadingOverlay'
import { visitPassActionTypes } from '../../../utils/constants'


const NoPassRequired = -1

class Passes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      modalPass: {
        id: null,
        name: ''
      },
      loading: true
    }
  }

  componentWillMount = () => {
    const {
      setHeaderBurger,
      setSignOutHeader,
    } = this.props

    setHeaderBurger()
    setSignOutHeader(true)
    this.getPasses()

    window.scrollTo(0, 0)
  }

  getPasses = () => {
    const { setPassTypes, store } = this.props

    api.getPasses(store.siteType).then((response) => {
      setPassTypes(response)
      this.setState({ loading: false })
    })
  }

  handleSelectPass = (pass) => {
    if (!this.isPassSelected(pass)) {
      const { history, setVisitPass } = this.props
      setVisitPass(pass)
      history.push('/passquestions')
    }
  }

  handleNoPassRequired = () => {
    const { visitId, store } = this.props
    const visitPass = {
      visitId,
      passTypeId: NoPassRequired,
      siteType: store.siteType
    }
    const { history } = this.props

    api.addVisitPass(visitPass)
      .then((response) => {
        if (response !== null && response.status === 200) {
          history.push('/WorkOrderHome')
        }
      })
  }

  displayPassStatus = (pass) => {
    const passSaved = this.isPassSelected(pass)

    return (
      <div className="ln-u-padding-top">
        {passSaved
          && this.showPassAsSaved(pass)
        }
      </div>
    )
  }

  isPassSelected = (pass) => {
    const { visitPasses } = this.props
    if (visitPasses) {
      const index = visitPasses.map(e => e.passTypeId).indexOf(pass.id)
      return index !== -1
    }
    return false
  }

  showPassAsSaved = pass => (
    <FlagWrapper>
      <FlagComponent>
        <div className="c_button-pass-saved">
          <Alert variant="info">
            <div style={{ margin: '0 1.4rem' }}>
              Saved
            </div>
          </Alert>
        </div>
      </FlagComponent>
      <FlagBody className="ln-u-text-align-right">
        <div className="ln-u-margin-top*3">
          <Link cta onClick={() => this.checkDeletePass(pass)}> Delete</Link>
        </div>
      </FlagBody>
    </FlagWrapper>

  )

  checkDeletePass = (pass) => {
    this.setState({ modalOpen: true, modalPass: pass })
  }

  deletePass = (pass) => {
    const { deleteVisitPass, visitPasses } = this.props

    const passToDelete = visitPasses.find(p => p.passTypeId === pass.id)

    this.setState({ loading: true, modalOpen: false })

    api.deleteVisitPass(passToDelete).then(() => {
      deleteVisitPass(passToDelete)
      this.setState({ loading: false })
    })
  }

  sendPassesForAuthorisation = (buttonText) => {
    const {
      history, visitPasses, username, visitId
    } = this.props

    this.setState({ loading: true })

    const authorisation = {
      actionTypeId: visitPassActionTypes.submitted,
      visitPassIds: visitPasses.map(a => a.id),
      actionedOn: new Date(),
      buttonText,
      visitId
    }

    api.addVisitAction(authorisation).then((res) => {
      if (res) {
        history.push('WorkOrderHome')
      }
    }).catch(() => { this.setState({ loading: false }) })
  }

  showChevron = (pass) => {
    if (!this.isPassSelected(pass)) {
      return <ArrowRight />
    }
    return ''
  }

  displayPasses = () => {
    const { passTypes } = this.props

    if (passTypes) {
      return passTypes.map(pass => (
        <div className="u-margin-bottom__small" key={pass.id}>
          <Card hard>
            <div className="ln-u-padding-sides*2 ln-u-padding-bottom*4">
              {this.displayPassStatus(pass)}
              <FlagWrapper onClick={() => this.handleSelectPass(pass)} className="ln-u-padding-top*3">
                <FlagComponent>
                  <Display3>
                    {pass.name}
                  </Display3>
                </FlagComponent>
                <FlagBody className="ln-u-text-align-right">
                  {this.showChevron(pass)}
                </FlagBody>
              </FlagWrapper>
            </div>
          </Card>
        </div>
      ))
    }
    return null
  }

  getFooter = () => (
    <FooterWithButton
      button={<OutlinedButton className="ln-c-button ln-c-button--full" fullWidth onClick={this.handleNoPassRequired}>I don&#39;t need a pass</OutlinedButton>}
    />
  )

  getModal = () => {
    const { modalOpen, modalPass } = this.state
    return (
      <DeletePassModal open={modalOpen} pass={modalPass} acceptClicked={this.deletePass} rejectClicked={() => this.setState({ modalOpen: false })} />
    )
  }

  render() {
    const { selectedWorkOrder, visitPasses } = this.props
    const { loading } = this.state

    const sendForAuthorisationButtonText = 'Send for authorisation'

    if (visitPasses === null || visitPasses.length === 0) {
      return (
        <WithFooter className="ln-c-card" footer={this.getFooter()}>
          {loading
            && <LoadingOverlay hasBackground />
          }
          <Title workOrderNumber={selectedWorkOrder} text="Select a work pass if required" />
          {this.displayPasses()}
        </WithFooter>
      )
    }

    return (
      <div>
        {loading
          && <LoadingOverlay hasBackground />
        }
        {this.getModal()}
        <Title workOrderNumber={selectedWorkOrder} text="Select another work pass if required" />
        {this.displayPasses()}
        <Card hard>
          <div className="ln-u-padding-bottom*2 ln-u-padding-sides*2">
            <div className="ln-u-padding-bottom*4 ln-u-padding-top*4 ln-u-padding-sides ln-u-text-align-center">
              <Text>When you have finished selecting passes you must send them to the manager to authorise.</Text>
            </div>
            <FilledButton
              className="ln-c-button ln-c-button--full"
              fullWidth
              onClick={() => this.sendPassesForAuthorisation(sendForAuthorisationButtonText)}
            >
              {sendForAuthorisationButtonText}
            </FilledButton>
          </div>
        </Card>
      </div>
    )
  }
}

Passes.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  selectedWorkOrder: PropTypes.string.isRequired,
  setHeaderBurger: PropTypes.func.isRequired,
  setSignOutHeader: PropTypes.func.isRequired,
  setVisitPass: PropTypes.func.isRequired,
  setPassTypes: PropTypes.func.isRequired,
  passTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })),
  deleteVisitPass: PropTypes.func.isRequired,
  visitId: PropTypes.number.isRequired,
  visitPasses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })),
  username: PropTypes.string.isRequired,
  store: PropTypes.shape({
    siteType: PropTypes.string.isRequired
  }).isRequired
}

Passes.defaultProps = {
  visitPasses: null,
  passTypes: []
}

export default Passes
