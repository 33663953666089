import React from 'react'
import { Container, FilledButton } from '@jsluna/react'
import { PropTypes } from 'prop-types'
import WithFooter from '../Footer'
import Text from './TermsAndConditionsText'

const onAccept = (props) => {
  const { storeContractor, history, contractor } = props

  let contractorView

  if (contractor) {
    contractorView = {
      forename: contractor.forename,
      surname: contractor.surname,
      email: contractor.email,
      employerId: contractor.employerId,
      jobRoleId: contractor.jobRoleId,
      telephoneNumber: contractor.telephoneNumber,
      vehicleRegistration: contractor.vehicleRegistration,
      employerName: contractor.employerName,
      acceptedTCs: true
    }
  } else {
    contractorView = {
      forename: '',
      surname: '',
      email: '',
      employerId: 0,
      jobRoleId: 0,
      telephoneNumber: '',
      vehicleRegistration: '',
      employerName: null,
      acceptedTCs: true
    }
  }
  storeContractor(contractorView)

  history.push('/register/details')
}

const getFooter = props => (
  <Container size="xs">
    <div className="ln-o-grid ln-o-grid--bottom ln-o-grid--matrix ln-u-soft-right ln-u-soft-left ln-u-soft-top">
      <div className="ln-o-grid__item ln-u-1/1 ln-u-1/3@xs">
        <div className="ln-u-push-bottom">
          <FilledButton className="ln-c-button ln-c-button--full ln-c-button--primary" onClick={() => onAccept(props)} fullWidth>
            Accept
          </FilledButton>
        </div>
      </div>
    </div>
  </Container>
)

const TermsAndConditions = (props) => {
  const { setHeader, setSignOutHeader } = props
  setHeader()
  setSignOutHeader(false)
  return (
    <WithFooter className="ln-c-card" footer={getFooter(props)}>
      <div className="ln-u-push-left ln-u-margin-top*2">
        <h2>Terms & Conditions</h2>
      </div>
      <Container size="xs">
        <div className="ln-u-push">
          <Text />
        </div>
      </Container>
    </WithFooter>
  )
}

TermsAndConditions.propTypes = {
  setHeader: PropTypes.func.isRequired,
  setSignOutHeader: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

export default TermsAndConditions
