import { connect } from 'react-redux'
import MultipleStores from './MultipleStores'
import { setHeaderBack, setHeaderSignOut } from '../../store/header/actions'
import { setSite } from '../../store/site/actions'
import { resetSignIn } from '../../store/signIn/actions'

const mapStateToProps = state => ({
  stores: state.sainsburysSites.sites,
  store: state.sainsburysSites.site
})

const mapDispatchToProps = dispatch => ({
  setHeader: (state) => {
    dispatch(setHeaderBack(state))
  },
  setChosenStore: (store) => {
    dispatch(setSite(store))
  },
  setSignOutHeader: (state) => {
    dispatch(setHeaderSignOut(state))
  },
  resetSignIn: () => {
    dispatch(resetSignIn())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(MultipleStores)
