import actions from './actions'

const workOrders = (state = { storeWorkOrders: [] }, action) => {
  switch (action.type) {
    case actions.setWorkOrders:
      return { ...state, storeWorkOrders: action.storeWorkOrders }
    default:
      return state
  }
}

export default workOrders
