import { connect } from 'react-redux'
import WorkOrders from './WorkOrders'
import { setHeaderBurger, setHeaderBack, setHeaderSignOut } from '../../../store/header/actions'
import { setVisitWorkOrder } from '../../../store/visit/actions'

const mapStateToProps = state => ({
  store: state.sainsburysSites.site,
  employerId: state.loggedInUser.user.employerId,
  workOrders: state.workOrders.storeWorkOrders
})

const mapDispatchToProps = dispatch => ({
  setHeaderBurger: (state) => {
    dispatch(setHeaderBurger(state))
  },
  setHeaderBack: (state) => {
    dispatch(setHeaderBack(state))
  },
  setWorkOrder: (workOrderNumber, companyName) => {
    dispatch(setVisitWorkOrder(workOrderNumber, companyName))
  },
  setSignOutHeader: (state) => {
    dispatch(setHeaderSignOut(state))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrders)
