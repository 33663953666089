import api from './api'
import { setUser } from '../store/loggedInUser/actions'
import { setVisit, setVisitWorkOrder } from '../store/visit/actions'
import { setSite } from '../store/site/actions'
import { setStoreWorkOrders } from '../store/workorders/actions'
import { setPassTypes } from '../store/passTypes/actions'
import { store } from '../store/index'

const performRedirect = (data, history) => {
  const visit = data.contractorState.contractor.openVisit

  if (visit) {
    store.dispatch(setVisit(visit))
    store.dispatch(setSite(data.contractorState.store))
    store.dispatch(setVisitWorkOrder(visit.workOrderNumber, data.contractorState.workOrderCompanyName))
  }

  delete data.contractorState.contractor.openVisit
  delete data.contractorState.workOrderCompanyName

  store.dispatch(setUser(data.contractorState.contractor))
  store.dispatch(setStoreWorkOrders(data.contractorState.workOrders))
  store.dispatch(setPassTypes(data.contractorState.passTypes))

  if (data.nextRoute.includes('signout')) {
    if (!data.contractorState.contractor.workOrdersEnabled || data.contractorState.store.siteType === 'Depot') {
      history.push({
        pathname: '/confirmation',
        state: { signIn: true }
      })
    }
  }

  history.push(data.nextRoute)
}

const redirectUser = (history) => {
  api.getUserState()
    .then((result) => { if (result) { performRedirect(result, history) } })
}

export default redirectUser
