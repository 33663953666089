import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import {
  Card,
  TextArea,
  FilledButton,
  Button,
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  ButtonGroupSecondary
} from '@jsluna/react'
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ArrowLeft } from '@jsluna/icons'
import { passQuestionResponseTypes } from '../../../../utils/constants'
import api from '../../../../utils/api'
import NumberSquare from '../NumberSquare/NumberSquare'
import { isTokenExpired } from '../../../../utils/storage'
import userManager from '../../../../utils/identity'
import logger from '../../../../utils/logger'


class Question extends Component {
  constructor(props) {
    super(props)
    this.state = {
      questionIndex: 0,
      textFieldValue: '',
      previousButtonText: '',
      userAnswers: [],
      isGoingBack: false,
      canPressButton: true
    }
  }

  SaveToDB = (questionResponse) => {

    let { questionIndex } = this.state
    const {
      data,
      history,
      setSelectedPasses,
      visitId,
      selectedPass,
      callBack,
      workOrderCompanyName,
      store,
      onError
    } = this.props
    const { userAnswers } = this.state

    if (questionResponse.nextQuestionId == null) {
      const pass = {
        visitId,
        passTypeId: selectedPass.id,
        answers: userAnswers,
        workOrderCompanyName,
        siteType: store.siteType
      }
      callBack(true, true)
      api.addVisitPass(pass)
        .then(async (response) => {
          if (response.status === 200) {
            const passId = await response.json()
            setSelectedPasses({ ...pass, id: passId })
            history.push('/passes')
          } else {
            this.setState({ canPressButton: true })
            onError()
          }
          callBack(false, true)
        })
    } else {
      questionIndex = data.findIndex(x => x.id === questionResponse.nextQuestionId)
      this.setState({ isGoingBack: false },
        this.setState({ previousButtonText: '', textFieldValue: '', questionIndex }))
    }
  }

  handleClick = (questionResponse) => {
    const { questionIndex, canPressButton } = this.state
    const { data } = this.props

    if (canPressButton) {
      this.setState({ canPressButton: false, previousButtonText: questionResponse.text }, () => {
        if (data[questionIndex].responseType === passQuestionResponseTypes.buttons) {
          setTimeout(() => {
            this.performQuestionTransition(questionResponse)
          }, 500)
        } else {
          this.performQuestionTransition(questionResponse)
        }
      })
    }
  }

  performQuestionTransition = (questionResponse) => {
    const { questionIndex, textFieldValue, userAnswers, previousQuestionId } = this.state
    const { data } = this.props

    if (isTokenExpired()) {
      userManager.signinRedirect()
    } else {
      const userResponse = {
        passQuestionId: data[questionIndex].id,
        answerText: textFieldValue,
        buttonText: questionResponse.text,
        previousQuestionId
      }

      const newUserAnswers = this.replaceOrInsertQuestionAnswer(userAnswers, userResponse)
      this.setState(() => ({ userAnswers: newUserAnswers, previousQuestionId: data[questionIndex].id }), () => this.SaveToDB(questionResponse))
    }
  }

  replaceOrInsertQuestionAnswer = (answersArray, answer) => {
    const { data } = this.props
    const { questionIndex } = this.state

    const existingAnswer = answersArray.filter(x => x.passQuestionId === data[questionIndex].id)
    const newAnswer = answer

    if (existingAnswer.length > 0) {
      const index = answersArray.findIndex(x => x.passQuestionId === data[questionIndex].id)

      answersArray.splice(index, 1, newAnswer)
    } else {
      answersArray.push(newAnswer)
    }

    return answersArray
  }

  endQuestionsWithoutSaving = (question) => {
    const { history, visitId, callBack } = this.props

    if (question.responseType === passQuestionResponseTypes.endPass) {
      history.push('/Passes')
    } else {
      callBack(true, false)
      api.removeWorkOrder(visitId)
        .then(() => {
          callBack(false, false)
          history.push('/workorders/false')
        })
        .catch(error => logger.logError(error))
    }
  }

  replaceOrInsertQuestionAnswer = (answersArray, answer) => {
    const { data } = this.props
    const { questionIndex } = this.state

    const existingAnswer = answersArray.filter(x => x.passQuestionId === data[questionIndex].id)
    const newAnswer = answer

    if (existingAnswer.length > 0) {
      const index = answersArray.findIndex(x => x.passQuestionId === data[questionIndex].id)

      answersArray.splice(index, 1, newAnswer)
    } else {
      answersArray.push(newAnswer)
    }

    return answersArray
  }

  displayNavButtons = () => {
    const { questionIndex, textFieldValue, previousButtonText } = this.state
    const { data } = this.props
    const { responses } = data[questionIndex]
    const disabledBtn = !textFieldValue.length

    if (data[questionIndex].responseType !== passQuestionResponseTypes.endPass && data[questionIndex].responseType !== passQuestionResponseTypes.endWorkOrder) {
      return (
        <ButtonGroupWrapper className="ln-u-flush-bottom ln-u-margin-top*2">
          <ButtonGroupPrimary>
            {(responses && responses.length === 1)
              && (
                <FilledButton
                  className="c-carousel-buttons-width__130"
                  disabled={!data[questionIndex].isOptional && disabledBtn}
                  onClick={() => this.handleClick(responses[0])}
                >
                  {responses[0].text}
                </FilledButton>
              )
            }
            {(responses && responses.length > 1)

              && responses.map((response) => {
                return (
                  <Button
                    className={previousButtonText === response.text
                      ? 'ln-c-button--filled ln-u-margin-left*2 c-carousel-buttons-width'
                      : 'ln-c-button--outlined ln-u-margin-left*2 c-carousel-buttons-width'}
                    key={questionIndex + response.text}
                    onClick={() => this.handleClick(response)}
                  >
                    {response.text}
                  </Button>)
              })
            }
          </ButtonGroupPrimary>
          <ButtonGroupSecondary>
            {data[questionIndex].number !== 1// back arrow shouldn't show if on the first question
              && (
                <div className="ln-u-padding-top">
                  <ArrowLeft size="large" onClick={this.goBack} />
                </div>
              )
            }
          </ButtonGroupSecondary>
        </ButtonGroupWrapper>
      )
    }

    return (
      <div className="ln-u-flush-bottom ln-u-margin-top*3">
        <FilledButton
          fullWidth
          hard
          onClick={() => this.endQuestionsWithoutSaving(data[questionIndex])}
        >
          {responses[0].text}
        </FilledButton>
      </div>
    )
  }

  goBack = () => {
    const { data } = this.props
    const { userAnswers, previousQuestionId } = this.state

    // Get details from the previous question
    const previousQuestion = userAnswers.find(x => x.passQuestionId === previousQuestionId)
    const newPreviousQuestionId = previousQuestion.previousQuestionId
    const newTextFieldValue = previousQuestion.answerText
    const previousButtonText = previousQuestion.buttonText
    const newQuestionIndex = data.findIndex(x => x.id === previousQuestion.passQuestionId)

    // remove any questions greater than the existing index
    userAnswers.length -= 1

    this.setState({ isGoingBack: true },
      this.setState({ previousButtonText, previousQuestionId: newPreviousQuestionId, questionIndex: newQuestionIndex, textFieldValue: newTextFieldValue, userAnswers }))
  }

  handleTextChange = (e) => {
    this.setState({ textFieldValue: e.target.value })
  }

  expirySelected = (e) => {
    this.setState({ textFieldValue: e.toString() })
  }

  childFactoryCreator = classNames => (
    child => (
      React.cloneElement(child, {
        classNames
      })
    )
  );

  transitionComplete = () => {
    this.setState({ canPressButton: true })
  }

  render() {
    const { data } = this.props
    const { questionIndex, textFieldValue, isGoingBack } = this.state

    return (
      <TransitionGroup
        childFactory={this.childFactoryCreator(isGoingBack ? 'backQuestion' : 'nextQuestion')}
      >
        <CSSTransition
          key={data[questionIndex].id}
          timeout={500}
          classNames={isGoingBack ? 'backQuestion' : 'nextQuestion'}
          onEntered={this.transitionComplete}
        >
          <Card className="ln-u-padding-top*2 c-carousel-slide u-align-top">

            <div className="u-wrap-word">{ReactHtmlParser(data[questionIndex].question)}</div>

            {
              data[questionIndex].responseType === passQuestionResponseTypes.freeText
              && (
                <TextArea
                  className="ln-u-margin-top*3"
                  name={`passTxtArea${data[questionIndex].id}`}
                  value={textFieldValue}
                  rows={8}
                  maxLength={250}
                  onChange={e => this.handleTextChange(e)}
                />
              )}
            {
              data[questionIndex].responseType === passQuestionResponseTypes.expiry
              && (
                <NumberSquare
                  className="ln-u-margin-top*3"
                  number={12}
                  clickEvent={e => this.expirySelected(e)}
                />
              )}
            {
              this.displayNavButtons()
            }
          </Card>
        </CSSTransition>
      </TransitionGroup>
    )
  }
}

export default Question

Question.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    question: PropTypes.string.isRequired,
    isOptional: PropTypes.bool.isRequired,
    number: PropTypes.number.isRequired,
    responseType: PropTypes.number.isRequired,
    responses: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string.isRequired,
      nextQuestionId: PropTypes.number,
    })).isRequired,
  })).isRequired,
  setSelectedPasses: PropTypes.func.isRequired,
  visitId: PropTypes.number.isRequired,
  selectedPass: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  callBack: PropTypes.func.isRequired,
  workOrderCompanyName: PropTypes.string.isRequired,
  store: PropTypes.shape({
    siteType: PropTypes.string.isRequired
  }).isRequired,
  onError: PropTypes.func.isRequired
}
