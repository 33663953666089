import api from './api'
import { logLevel } from './constants'

const sendToApi = (level, message) => {
  // Checking network connection before logging errors
  if (navigator.onLine) {
    api.logError(level.name, message)
  }
}

const logger = {
  logError(error) {
    sendToApi(logLevel.error, error)
  },

  logWarning(warning) {
    sendToApi(logLevel.warning, warning)
  },

  logInformation(info) {
    sendToApi(logLevel.info, info)
  }
}

export default logger
