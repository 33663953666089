import { call, put, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import signInActions, { setSignInPending } from './actions'
import api from '../../utils/api'
import { setVisit, setVisitWorkOrder } from '../visit/actions'
import { setStoreWorkOrders } from '../workorders/actions'
import { sendMessage } from '../../utils/appHelper'

const queryCloseProperties = (lat, lon) => call(api.getClosestProperties, lat, lon)
const queryGeofenceRadius = () => call(api.getGeofenceRadius)
const queryWorkOrdersRequired = employerId => call(api.areWorkOrdersRequried, employerId)
const getWorkOrdersByProperty = (siteCode, siteType) => call(api.getWorkOrdersBySite, siteCode, siteType)
const performSignIn = details => call(api.signIn, details)


const getProperty = state => state.sainsburysSites.site
const getDeviceType = state => state.device.deviceType
const getContractorId = state => state.loggedInUser.user.id
const getEmployerId = state => state.loggedInUser.user.employerId

function* signIn(action) {
  yield put(setSignInPending(true))

  try {
    const properties = yield queryCloseProperties(action.latitude, action.longitude)
    const signInProperty = yield select(getProperty)

    if (!properties) { // no properties found in current location
      yield put({ type: signInActions.setSignIn, status: { hasWarning: true, statusText: 'You are not close to any sites.' } })
      yield put(push('/SignIn'))
    } else if (properties.findIndex(p => p.code === signInProperty.code
      && p.propertyType === signInProperty.propertyType) === -1) { // signin property is not in current location
      yield put({ type: signInActions.setSignIn, status: { hasWarning: true, statusText: 'Your location has changed. Please try again.' } })
      yield put(push('/SignIn'))
    } else {
      const deviceType = yield select(getDeviceType)
      const contractorId = yield select(getContractorId)

      const details = {
        storeId: signInProperty.code,
        storeName: signInProperty.name,
        deviceType,
        latitude: signInProperty.latitude,
        longitude: signInProperty.longitude,
        signedInSiteTypeId: signInProperty.siteType
      }

      const signInResult = yield performSignIn(details)

      if (signInResult !== null && signInResult !== undefined) {
        if (signInResult === 0) {
          yield put({ type: signInActions.setSignIn, status: { alreadySignedIn: true, statusText: 'You are already signed in. Please sign out.' } })
        } else {
          yield put(setVisit({ id: signInResult, visitPasses: [] }))
          const radius = yield queryGeofenceRadius()
          sendMessage('signIn', Object.assign(details, { radius }))

          const employerId = yield select(getEmployerId)
          const workOrdersRquired = yield queryWorkOrdersRequired(employerId)

          if (!workOrdersRquired || signInProperty.siteType === 'Depot' ) {
            yield put(push({
              pathname: '/confirmation',
              state: { signIn: true }
            }))
          } else {
            const workOrders = yield getWorkOrdersByProperty(signInProperty.code, signInProperty.siteType)
            if (workOrders.length === 0) {
              yield put(setVisitWorkOrder(null))
              yield put(setStoreWorkOrders([]))
              yield put(push('/WorkOrderHome'))
            } else {
              yield put(setStoreWorkOrders(workOrders))
              yield put(push('workorders/false'))
            }
          }
        }
      }
    }
  } finally {
    yield put(setSignInPending(false))
  }
}


export default signIn
