import { connect } from 'react-redux'
import TaskSelection from './TaskSelection'
import { setHeaderBack, setHeaderSignOut } from '../../store/header/actions'
import { setStoreTask,getTask } from '../../store/task/actions'

const mapStateToProps = state => ({
  userName: state.user.userName,
  contractorId: state.loggedInUser.user.id,
  deviceType: state.device.deviceType,
  store: state.sainsburysSites.site,
  stores: state.sainsburysSites.sites,
  employerId:state.loggedInUser.user.employerId,
  signInState: state.signIn,
  task: state.task,
})

const mapDispatchToProps = dispatch => ({
  setHeader: (state) => {
    dispatch(setHeaderBack(state))
  },
  setSignOutHeader: (state) => {
    dispatch(setHeaderSignOut(state))
  },
  setStoreTask: (task) => {
    dispatch(setStoreTask(task))
  },
  getTask: () => {
    dispatch(getTask())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskSelection)
