import { connect } from 'react-redux'
import MerchandiserSignIn from './MerchandiserSignIn'
import { setHeaderBurger, setHeaderBack, setHeaderSignOut } from '../../store/header/actions'

const mapStateToProps = state => ({
  signedInStore: state.sainsburysSites.site,
  contractorId: state.loggedInUser.user.id,
  employerId: state.loggedInUser.user.employerId,
  store: state.sainsburysSites.site,
  stores: state.sainsburysSites.sites,
})

const mapDispatchToProps = dispatch => ({
  setHeaderBurger: (state) => {
    dispatch(setHeaderBurger(state))
  },
  setHeaderBack: (state) => {
    dispatch(setHeaderBack(state))
  },
  setSignOutHeader: (state) => {
    dispatch(setHeaderSignOut(state))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MerchandiserSignIn)
