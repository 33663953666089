import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import {
  Container,
  FieldInfo
} from '@jsluna/react'
import StoreButton from './StoreButton/StoreButton'

class MultipleStores extends Component {
  constructor(props) {
    super(props)
    this.state = {
      gpsError: false,
      gpsErrorText: ''
    }
  }

  componentDidMount() {
    const { setHeader, setSignOutHeader, resetSignIn } = this.props
    setHeader()
    setSignOutHeader(false)
    resetSignIn()
    this.setLocation()
  }

  setLocation = () => {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(this.locationSuccess, this.locationError)
    }
  }

  locationSuccess = () => null

  locationError = (error) => {
    const { message } = error
    let msg = message

    if(error.code === 1){
      msg = 'You need to turn on location services to find your nearest store and sign in'
    }

    if (error.code === 2) {
      msg = 'GPS is currently unavailable'
    }

    this.setState({ gpsError: true, gpsErrorText: msg })
  }

  checkStores = () => {
    const { stores, } = this.props
    const { gpsError, gpsErrorText } = this.state

    if (gpsError) {
      return this.showError(gpsErrorText)
    } if (stores === null || stores === undefined || stores.length === 0) {
      return this.showError('You are not close to any sites')
    }

    return null
  }

  showError = errorMessage => (
    <div className="ln-u-body-2-fixed">
      <FieldInfo error>{errorMessage}</FieldInfo>
    </div>
  )

  handleStoreSelection = (store) => {
    const { setChosenStore, history } = this.props
    setChosenStore(store)
    history.push('/signin')
  }

  populateButtons = () => {
    const { stores } = this.props
    const { gpsError } = this.state

    if (!gpsError && stores && !stores.isEmpty) {
      return (
        <div className="ln-u-push">
          {
            stores.map(ss => <StoreButton key={ss.code} store={ss} onClick={store => this.handleStoreSelection(store)} />)
        }
        </div>
      )
    }
  }

  render() {
    return (
      <Container className="ln-o-container ln-o-container--soft ln-u-soft-bottom ln-u-push-top">
        <h2>Pick a site</h2>
        <Container size="xs">
          {this.checkStores()}
          <div>
            {this.populateButtons()}
          </div>
        </Container>
      </Container>
    )
  }
}

MultipleStores.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  setHeader: PropTypes.func.isRequired,
  resetSignIn: PropTypes.func.isRequired,
  setSignOutHeader: PropTypes.func.isRequired,
  setChosenStore: PropTypes.func.isRequired,
  stores: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    siteType: PropTypes.string.isRequired,
    distance: PropTypes.number.isRequired
  }))
}

MultipleStores.defaultProps = {
  stores: null
}

export default MultipleStores
