import React, { Component } from 'react'
import { PropTypes } from 'prop-types'

import {
  OutlinedButton,
} from '@jsluna/react'

class StoreButton extends Component {
  handleStoreSelection = () => {
    const { onClick, store } = this.props
    onClick(store)
  }

  populateButtons = () => {
    const { store } = this.props
    if (store) {
      return (
        <div className="ln-u-soft-bottom">
          <OutlinedButton fullWidth onClick={this.handleStoreSelection}>{`${store.name} (${store.siteType})`}</OutlinedButton>
        </div>
      )
    }
    return null
  }

  render() {
    return (
      <div className="ln-u-soft-bottom">
        {this.populateButtons()}
      </div>
    )
  }
}

StoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  store: PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    siteType: PropTypes.string.isRequired
  }).isRequired,
}

export default StoreButton
