import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, FilledButton, CheckboxField, TextButton } from "@jsluna/react";
import api from "../../utils/api";
import LoadingOverlay from "../LoadingOverlay";
import { Alert, AlertIcon } from "@jsluna/alert";
import { ErrorCircle } from "@jsluna/icons";
import '@jsluna/alert/css/main.css'

class TaskSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisable: true,
      taskList: [{}],
      error: "",
      isCheckboxChecked: true,
      checkboxArr: [],
      alldata: props.task.taskSelectedItems,
    };
  }

  componentDidMount() {
    this.getTaskList();
  }
  isValidCheckboxItems = () => {
    let isChecked = false;
    this.state.taskList.map((e) => {
      isChecked =
        isChecked ||
        document.getElementById(`checkbox-field-1-${e.value}`).checked;
    });

    this.setState((prevState) => ({
      ...prevState,
      isCheckboxChecked: isChecked,
    }));
    if (isChecked) {
      this.setState({
        error: "",
      });
    }
    return isChecked;
  };

  handleSubmit = () => {
    const { history, setStoreTask, getTask } = this.props;
    if (this.isValidCheckboxItems()) {
      let checkBoxArray = [];
      this.state.taskList.map((e) => {
        checkBoxArray.push({
          value: document.getElementById(`checkbox-field-1-${e.value}`).value,
          label: document.getElementById(`checkbox-field-1-${e.value}`)
            .labels[0].innerText,
          defaultChecked: document.getElementById(`checkbox-field-1-${e.value}`)
            .checked,
        });
      });
      setStoreTask(checkBoxArray);

      this.setState({
        checkboxArr: checkBoxArray,
      });

      //   const {history}= this.props;
      history.push("/task/time");
    } else {
      this.setState({
        error: " ",
      });
    }
  };

  getTaskList = () => {
    const { task } = this.props;
    const loadOptions = api
      .getAllTasks()
      .then((data) => {
        this.setState({
          taskList: data.map((item) => ({
            value: item.id,
            label: item.taskName,
            defaultChecked: false,
          })),
        });
      })
      .catch((e) => console.log(e));
    return loadOptions;
  };

  render() {
    if (! Object.keys(this.props.task.taskSelectedItems).length>=1  && !this.state.taskList[0].label) {
      return <LoadingOverlay hasBackground text="Loading..." />;
    } else {
      return (
        <Container className="ln-o-container ln-o-container--soft ln-u-soft-bottom ln-u-margin-top*2">
          <Container size="xs">
            <div className="ln-c-form-group ln-o-grid__item ln-u-1/1@xs ln-u-soft-right">
              <h2>What tasks are you in store to do?</h2>
            </div>
            <div className="ln-o-grid__item ln-u-soft-right">
              <CheckboxField
                name="checkbox-field-1"
                error={this.state.error}
                options={
                  Object.keys(this.props.task.taskSelectedItems).length>=1? this.props.task.taskSelectedItems
                      : this.state.taskList
                }
                onClick={() => {
                  this.isValidCheckboxItems();
                }}
              />
              {this.state.error && (
                <div>
                  <Alert variant="error">
                    <AlertIcon>
                      <ErrorCircle id="task-alert-icon"/>
                    </AlertIcon>
                    Please select one or more tasks
                  </Alert>
                </div>
              )}
            </div>
            <div className="ln-o-grid ln-o-grid--bottom ln-o-grid--matrix ln-u-soft-right ln-u-soft-left ln-u-soft-top">
              <div className="ln-o-grid__item ln-u-1/1 ln-u-1/3@xs">
                <FilledButton
                  className="ln-c-button ln-c-button--full ln-c-button--primary"
                  type="button"
                  onFocus={() => this.forceUpdate()}
                  onClick={this.handleSubmit}
                >
                  Next
                </FilledButton>
                <TextButton
                  fullWidth
                  onClick={() => {
                    const { history } = this.props;
                    history.push("/supplier");
                  }}
                >
                  Back
                </TextButton>
              </div>
            </div>
          </Container>
        </Container>
      );
    }
  }
}
export default TaskSelection;
