 import React, {Component} from 'react'
 import { PropTypes } from 'prop-types'
import {
    Container,
    FilledButton,
    AsyncAutocompleteField
  } from '@jsluna/react'

  import { Search } from '@jsluna/icons'
  import api from '../../utils/api'
  class SupplierSelection extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        supplierName: this.props.supplierName,
        supplierId: 0,
      }
    }

    componentWillUnmount() {
      this.storeSupplier()
    }

    getSuppliers = () => {
        const loadOptions = api.getAllSuppliers()
          .then(data => data.map(item => ({ value: item.id, label: item.name })))
          .catch(e => console.log(e))
    
        return loadOptions
      }
    
      handleSupplierDropdownChange = (event) => {
        if (event) {
          this.setState({ supplierId: parseInt(event.value, 10) })
          if (event.value !== 0) {
            localStorage.setItem('_selectedSupplier',event.label)
            this.setState({ supplierName: event.label,              
            })
            const selectedTime = this.props.taskTime
            selectedTime.minutes = []
            selectedTime.hours = []
            this.props.setTaskTime(selectedTime)
          }
        }
      } 

      isFieldIsValid = () => {
        const {
          supplierId
        } = this.state
    
        let supId = supplierId
    
        if (document.getElementsByClassName('ln-c-autocomplete__input')) {
          const elements = document.getElementsByClassName('ln-c-autocomplete__input')
          if (elements[0]) {
            supId = elements[0].defaultValue
          }
        }
    
        if (supId
        ) {
          const selectedTime = this.props.taskTime
          selectedTime.minutes = []
          selectedTime.hours = []
          this.props.setTaskTime(selectedTime)
          return true
        }
        return false
      }

      clearSupplierField = () => {
        const textArea = document.querySelector('[aria-autocomplete="list"]')
        textArea.value = ''
      }

      handleSubmit = async () => {
        const { history } = this.props        
        if (this.isFieldIsValid()) {
          history.push('/task')
          }
         else {
          history.push('/supplier')
        }
      }
      
      storeSupplier = () => {
        const {
          supplierName,
          supplierId
        } = this.state

        const { contractor, setSupplier,setStoreTask,setSupplierName } = this.props

        const supplierData = {
          supplierName,
          supplierId,
          acceptedTCs: contractor ? contractor.acceptedTCs : false
        }

        setSupplier(supplierData)
        setSupplierName(supplierName)
        setStoreTask({});
      }

      render(){

        const clearSupplierButtonClicked = async () => {
            const delay = ms => new Promise(res => setTimeout(res, ms))
            await delay(25)
            this.setState({ supplierId: 0, supplierName: undefined, retainSupplierName:''})
            this.forceUpdate()
          }
      
          const clearSupplierButton = document.querySelector('[aria-label="Clear the field"]')

          if (clearSupplierButton) {
            clearSupplierButton.addEventListener('click', clearSupplierButtonClicked)
          }

        return(
        <Container  className="ln-o-container ln-o-container--soft ln-u-soft-bottom ln-u-margin-top*2">
          <Container size="xs">
            <div className="ln-c-form-group ln-o-grid__item ln-u-1/1@xs ln-u-soft-right">
             <h2>Which supplier are you working for today?</h2>
            </div>
            <div className="ln-o-grid__item ln-u-soft-right" onFocus={() => this.forceUpdate()} onBlur={() => this.clearSupplierField()}>
              <AsyncAutocompleteField
                icon={<Search />}
                name="Supplier"
                label="Find a supplier"
                required
                minChars={3}
                defaultSelectedOption={{ label: this.state.supplierName }}
                loadOptions={() => this.getSuppliers()}
                onSelect={e => this.handleSupplierDropdownChange(e)}
             />
            </div>
            <div className="ln-o-grid ln-o-grid--bottom ln-o-grid--matrix ln-u-soft-right ln-u-soft-left ln-u-soft-top">
              <div className="ln-o-grid__item ln-u-1/1 ln-u-1/3@xs">
                <FilledButton className="ln-c-button ln-c-button--full ln-c-button--primary" type="button" onFocus={() => this.forceUpdate()} onClick={this.handleSubmit} disabled={!this.isFieldIsValid()}>Next</FilledButton>
              </div>
            </div>
          </Container>
        </Container>
        )
      }
}

SupplierSelection.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  storeSupplier: PropTypes.func.isRequired,
  supplier: PropTypes.shape({
    supplierName: PropTypes.string,
    supplierId: PropTypes.number
  })
}

SupplierSelection.defaultProps = {
  supplier: ''
}

export default SupplierSelection;
